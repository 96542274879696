
/* body{
  overflow: auto;
  background-color: #EEEEEE !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
} */

.swal2-container{
  z-index : 2000 !important;
}

.MuiBackdrop-root{
  /* background-color: Transparent !important; */
}

.scroll::-webkit-scrollbar-track,body::-webkit-scrollbar-track,.scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: white;
}

.scroll::-webkit-scrollbar,
.scroll::-webkit-scrollbar,
body::-webkit-scrollbar {
	width: 7px;
	background-color: #BDBDBD;
}

.scroll::-webkit-scrollbar-thumb,
.scroll::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
	background-color: #BDBDBD;
	border: 2px solid ref;
}

.MuiDialog-paper{
  overflow-y: hidden !important;
  border-radius: 40px !important;
}

.menuTemp1 .MuiPaper-root{
background-color: #F8F9FA !important;
}
.menuTemp19 .MuiPaper-root{
background-color: rgba(10,10,10,0.9) !important;
}
.menuTemp9 .MuiPaper-root{
background-color: white !important;
}
.menuTemp10 .MuiPaper-root{
background-color: white !important;
}
.menuTemp10 .MuiDrawer-paper{
  border-left: 20px solid   !important;
  border-image: linear-gradient(270deg, rgba(20,56,166,1), rgba(13,20,64,1)) 1;
}
.menuTemp15 .MuiPaper-root{
background-color: white !important;
}
.menuTemp15 .MuiDrawer-paper{
  border-left: 20px solid   !important;
  border-image: linear-gradient(90deg, #B20A2C 0%, #FFFBD5 100%) 1;
}
.menuTemp11 .MuiPaper-root{
background-color: #00A7A6 !important;
}

.MuiTabs-indicator{
  background-color: transparent !important;
}
.tmp10 .MuiButtonBase-root,.tmp15 .MuiButtonBase-root {
  background-color: transparent  !important;
  z-index: 1000 !important;
}
.tmp10 .CarouselItem,.tmp15 .CarouselItem {
  position: relative;
  z-index: 100;
}
.tmp11 .mainPanelCarousel,.tmp16 .mainPanelCarousel,.tmp17 .mainPanelCarousel {
  padding: 30px 0px 0px 30px !important;
  margin-top: 40px !important;
}
.tmp11 .cardRoot,.tmp16 .cardRoot,.tmp17 .cardRoot{
  background-color: transparent !important;
  border: 1px solid white !important;
  top: 0 !important;
  min-width: 300px;
  max-width: 300px !important;
  min-height: 350px !important;
  height: 350px !important;
  margin: 10px 10px 10px 2px !important;
  cursor: pointer !important;
  z-index: 1000 !important;
  position: relative !important;
}
.tmp16 .cardRoot{
  background-color: #F2F2F2 !important;
}

.tabs_modal .MuiBottomNavigationAction-root.Mui-selected{
  border-bottom: 2px solid white !important;
  color: white !important;
}

 .tmp11 div[class*='Carousel-buttonWrapper'] {
  background-color:  transparent !important;
}

.tmp17 div[class*='Carousel-buttonWrapper'] {
  background-color:  transparent !important;
}

.tmp16  button[aria-label*='Next'], .tmp16  button[aria-label*='Previous'] {
  background-color: transparent  !important;
}

.tmp17  button[aria-label*='Next'], .tmp17  button[aria-label*='Previous'] {
  background-color: transparent  !important;
}

.tmp11  button[aria-label*='Next'], .tmp11  button[aria-label*='Previous'] {
  background-color: transparent  !important;
  border: 1px solid #39AFB4 !important;
  border-radius: 10px;
  width: 85px !important;
  height: 348px !important;
  margin: 12px 0 0px 0px !important;
  text-align: center !important;
  z-index: 1000 !important;
  color: #39AFB4 !important;
  position: initial;
  top: 30%;
  bottom: 50%;
}

.tmp17  button[aria-label*='Next'], .tmp17  button[aria-label*='Previous'] {
  background-color: transparent  !important;
  border: 1px solid #383838 !important;
  border-radius: 10px;
  width: 85px !important;
  height: 348px !important;
  margin: 12px 0 0px 0px !important;
  text-align: center !important;
  z-index: 1000 !important;
  color: #E52C34 !important;
  position: initial;
  top: 30%;
  bottom: 50%;
}

.tmp11 .cardRoot:hover{
  box-shadow: 0 0 20px #000000;
}

.tmp17 .cardRoot:hover{
  box-shadow: 0 0 20px #000000;
}

.tmp11 #number1,.tmp11 #number2 {
display: none;
}
.tmp16 #number1,.tmp16 #number2 {
display: none;
}
.tmp17 #number1,.tmp17 #number2 {
display: none;
}

#products .MuiStepIcon-root.MuiStepIcon-completed{
  color: #d32f2f !important;
}

#products .MuiStepIcon-root.MuiStepIcon-active{
  color: #d32f2f !important;
}
 
 @media (max-width: 1200px) { 
    .tmp11 #number1,.tmp11 #number3 {
      display: none;
      }
    .tmp11 #number2 {
      display: block;
      }
    .tmp16 #number1,.tmp16 #number3 {
      display: none;
      }
    .tmp16 #number2 {
      display: block;
      }
    .tmp17 #number1,.tmp17 #number3 {
      display: none;
      }
    .tmp17 #number2 {
      display: block;
      }
      
 }

 @media (max-width: 960px) { 
  .tmp11  button[aria-label*='Previous'] {
    display: none;
    }

  .tmp17  button[aria-label*='Previous'] {
    display: none;
    }
      
 }


 @media (max-width: 840px) { 

  .tmp11 #number2,.tmp11 #number3 {
    display: none;
    }
  .tmp11 #number1 {
    display: block;
    }
  .tmp16 #number2,.tmp16 #number3 {
    display: none;
    }
  .tmp16 #number1 {
    display: block;
    }
  .tmp17 #number2,.tmp17 #number3 {
    display: none;
    }
  .tmp17 #number1 {
    display: block;
    }
}

@media (max-width: 599px) {  

  .MuiDialog-root{
    width: 100% !important;
  } 
  .MuiDialog-paper{
    width: 98% !important;
    margin : 0px !important;
  }
  
  .MuiPickersBasePicker-pickerView {
    max-width : none !important;
  }
  .tmp11 .mainPanelCarousel{
    padding: 30px 0 0 0 !important;
  }
  .tmp16 .mainPanelCarousel{
    padding: 30px 0 0 0 !important;
  }
  .tmp11 .cardRoot{
  width: 100% !important;
  min-width: 280px !important;
  margin: 10px 1px 10px 1px !important;

  }
  .tmp16 .cardRoot{
  width: 100% !important;
  min-width: 280px !important;
  margin: 10px 1px 10px 1px !important;
  }
}


@media (max-width: 480px) {  

  .tmp11   button[aria-label*='Next'] {
    height: 72px !important;
    width: 280px !important;
    position: relative !important;
    top: 83% !important;
    right: 30% !important;
  }

  .tmp11 .mainPanelCarousel {
    padding: 0 !important;
    margin-top: 40px !important;
  }

  .tmp17   button[aria-label*='Next'] {
    height: 72px !important;
    width: 280px !important;
    position: relative !important;
    top: 83% !important;
    right: 30% !important;
  }

  .tmp17 .mainPanelCarousel {
    padding: 0 !important;
    margin-top: 40px !important;
  }

}

@media (max-width: 425px) {  
  .tmp11  button[aria-label*='Next'] {
    right: 20% !important;
  }
  .tmp17   button[aria-label*='Next'] {
    right: 20% !important;
  }
}

@media (max-width: 400px) {  
  .tmp11  button[aria-label*='Next'] {
    right: 15% !important;
  }
  .tmp17   button[aria-label*='Next'] {
    right: 15% !important;
  }
}

@media (max-width: 380px) {  
  .tmp11  button[aria-label*='Next'] {
    right: 10% !important;
  }
  .tmp17   button[aria-label*='Next'] {
    right: 10% !important;
  }
}

@media (max-width: 340px) {  
  .tmp11  button[aria-label*='Next'] {
    right: 6% !important;
  }
  .tmp17   button[aria-label*='Next'] {
    right: 6% !important;
  }
}

@media (max-width: 325px) {  
  .tmp11   button[aria-label*='Next'] {
    width: 285px !important;
    right: 4% !important;
  }
  .tmp17   button[aria-label*='Next'] {
    width: 285px !important;
    right: 4% !important;
  }
}


.tmp22Wait .MuiLinearProgress-barColorSecondary {
  background-color: #6ed67b;
}


.tmp22Wait .MuiLinearProgress-colorSecondary {
  background-color: #22643f;
}
